import React, { useContext } from "react";
import ReactPixel from "react-facebook-pixel";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { getParameterByName } from "../../Utility/Helper";

require("dotenv").config();
const FbPixel = () => {
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  let current_domain = visitorParameters.data.existingdomain;
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");
  const nextPage = query.get("next_page");
  const fbp_cookie = query.get("fbp_id");
  const fbp_id = fbp_cookie != undefined && fbp_cookie != "" ? fbp_cookie : "";
  console.log("pixel- " + fbp_id);
  console.log(visitorParameters);

  if (queryString != null) {
    console.log("current" + current_domain);
    console.log("redirect" + visitorParameters.data.redirectDomain);

    if (queryString.indexOf(`&${field}=`) !== -1) {
      const pid = getParameterByName(field, queryString);
      console.log("fb pixel iffff");
      console.log("vistors parameters");
      console.log(visitorParameters);
      const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(pid, advancedMatching, options);
      ReactPixel.pageView();
      // ReactPixel.track("Lead");
      ReactPixel.track("Lead", {}, { eventID: fbp_id });
      console.log(`insidefbp- ${fbp_id}`);

      const uuid = visitorParameters?.visitor_parameters?.uuid;

      // history.push(`/otp_blackhorse?uuid=${uuid}`);

      if (splitName == "UNFR_0602UFVCC_A1") {
        history.push(`/${nextPage}?uuid=${uuid}&splitName=${splitName}`);
      }

      if (splitName == "UNFR_0602UFVCC_A2") {
        history.push(`/${nextPage}?uuid=${uuid}&splitName=${splitName}`);
      }

      if (splitName == "UNFR_0602UFBH_A1") {
        history.push(`/${nextPage}?uuid=${uuid}&splitName=${splitName}`);
      }
      if (splitName == "UNFR_0602UFMB_A1_1") {
        history.push(
          "/"+nextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid+"&splitName="+splitName
        );
      }
      if (splitName == "UNFR_0602UFMB_A1_5") {
        history.push(
          "/"+nextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid+"&splitName="+splitName
        );
      }
      if (splitName == "UNFR_0602UFMB_A1_6") {
        history.push(
          "/"+nextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid+"&splitName="+splitName
        );
      }
      if (splitName == "UNFR_0602UFMB_A1_6_2") {
        history.push(
          "/"+nextPage+"?uuid=" + visitorParameters.visitor_parameters.uuid+"&splitName="+splitName
        );
      }
    } else {
      console.log("fb pixel else");
    }
  }

  return <></>;
};

export default FbPixel;
