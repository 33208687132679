import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";


const RadioQuestiannaireC2 = (props) => {
  const mainQuestion = props.questionnaire.question;
  const radioName = 'question_' + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;

  const radioId0 = (props.questionnaire.answers[0]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[0].value : '';
  const radioId1 = (props.questionnaire.answers[1]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[1].value : '';
  const radioId2 = (props.questionnaire.answers[2]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[2].value : '';
  const radioId3 = (props.questionnaire.answers[3]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[3].value : '';

  const radioValue0 = (props.questionnaire.answers[0]) ? props.questionnaire.answers[0].value : '';
  const radioValue1 = (props.questionnaire.answers[1]) ? props.questionnaire.answers[1].value : '';
  const radioValue2 = (props.questionnaire.answers[2]) ? props.questionnaire.answers[2].value : '';
  const radioValue3 = (props.questionnaire.answers[3]) ? props.questionnaire.answers[3].value : '';

  const radioLabel0 = (props.questionnaire.answers[0]) ? props.questionnaire.answers[0].label : '';
  const radioLabel1 = (props.questionnaire.answers[1]) ? props.questionnaire.answers[1].label : '';
  const radioLabel2 = (props.questionnaire.answers[2]) ? props.questionnaire.answers[2].label : '';
  const radioLabel3 = (props.questionnaire.answers[3]) ? props.questionnaire.answers[3].label : '';


  return (
    <>
      <section className={`question_block slide-top  slideSeptember ${props.showQuestionSlide}`}>
        <div className="row" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}
              </h1>
              {question_id == '28' &&
                <div class="btn-wrap text-center text-lg-start pb-3">
                  <a class="btn_link" target="_blank" href="https://allegiant.co.uk/2022/07/your-car-and-your-unaffordable-finance-claim/"> Unsure? See our helpful article here</a>
                </div>}

              {question_id == '29' &&
                <div className="light-bg mb-2">
                  'Disposable income’ is the amount of money left over from a regular income, once regular living costs and regular expenditure have been deducted.
                </div>}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">

                {radioId0 &&
                  <div className="mb-2 mb-lg-3" >
                    <div className="btn-date nextgoBtn13" id="" >
                      <input type="radio" id={radioId0} name={radioName} value={radioValue0} className="" data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
                      <label htmlFor={radioId0} className=" btn-cml">{radioLabel0}</label>
                    </div>
                  </div>}

                {radioId1 &&
                  <div className="mb-2 mb-lg-3 nextgoBtn13" id="">
                    <div className="btn-date ">
                      <input type="radio" id={radioId1} name={radioName} value={radioValue1} className="" data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
                      <label htmlFor={radioId1} className=" btn-cml">{radioLabel1}</label>
                    </div>
                  </div>}

                {radioId2 &&
                  <div className="mb-2 mb-lg-3 nextgoBtn13" id="">
                    <div className="btn-date ">
                      <input type="radio" id={radioId2} name={radioName} value={radioValue2} className="" data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
                      <label htmlFor={radioId2} className=" btn-cml">{radioLabel2}</label>
                    </div>
                  </div>}

                {radioId3 &&
                  <div className="mb-2 mb-lg-3" >
                    <div className="btn-date nextgoBtn13" id="" >
                      <input type="radio" id={radioId3} name={radioName} value={radioValue3} className="" data-question_id={question_id} onClick={props.slideChange} />
                      <label htmlFor={radioId3} className=" btn-cml">{radioLabel3}</label>
                    </div>
                  </div>}

                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <button type="button" className="btn-back " id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide}>Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RadioQuestiannaireC2;
