import { useState } from 'react';
import { Api } from '../api/Api';

export const useAgreeTermsIngestion = () => {
    const [isLoadingAgree, setIsLoadingAgree] = useState(false);

    const saveAgreeTermsIngestion = async(user_id, uuid, fieldName, fieldValue, source) => {
        setIsLoadingAgree(true);
        const response = await Api.post("v1/agree-terms", {
            user_id,
            uuid,
            fieldName,
            fieldValue,
            source
        });
        setIsLoadingAgree(false);
        return response;
    }
    const saveConfirmation = async(user_id, uuid, fieldName) => {
        setIsLoadingAgree(true);
        const response = await Api.post("v1/save-confirmation", {
            uuid,
            user_id,
            fieldName,
        });
        setIsLoadingAgree(false);
        return response;
    }

    const savePreviewClicks = async(user_id, user_uuid, labelName) => {
        setIsLoadingAgree(true);
        const response = await Api.post("v1/save-preview-click", {
            user_id,
            user_uuid,
            labelName
        });
        setIsLoadingAgree(false);
        return response;
    }
    return { saveAgreeTermsIngestion, saveConfirmation,savePreviewClicks, isLoadingAgree }
}