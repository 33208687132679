import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";


const SelectQuestiannaire = (props) => {
  const mainQuestion = props.questionnaire.question;
  const selectName = 'question_' + props.questionnaire.questionId;
  const dropValues = props.questionnaire.answers[0].options;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState('d-none');

  const setValue = (e) => {
    setInputValue(e.target.value);
    (e.target.value != '') ? setShowError('d-none') : setShowError('d-block');
  }

  const validatePage = (evt) => {
    (inputValue) ? props.slideChange(evt, selectName) : setShowError('d-block');
  }
  return (
    <>
      <section class={`question_block slide-top slide33 ${props.showQuestionSlide}`}>
        <div class="row">
          <div class="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}
              </h1>
              {question_id == '31' &&
                <div className="light-bg mb-2">
                  Please do not include any mortgage or rental payment within your answer.
                </div>}
            </div>

          </div>
          <div class="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div class="  d-flex align-items-center inner-text justify-content-center">
              <div class="text-center">
                <div class="mb-3">
                  <select class="form-select input-box " aria-label=" " name={selectName} id={selectName} onChange={(e) => { setValue(e) }}>

                    {dropValues.map((dropValue, index) => (
                      <option key={index} value={dropValue.value}>{dropValue.label}</option>
                    ))}
                  </select>
                  <span className={`error_msg error_msg_custom ${showError}`} id="email_err">Please select amount</span>
                </div>

                <div class="mb-3">
                  <button type="button" class="btn com_bnt " id={`next_${question_id}`}
                    name={`next_${question_id}`} data-question_id={question_id} onClick={(e) => { validatePage(e) }}> Next</button>
                </div>
                <div class="mb-3 mt-4">
                  <div class="">
                    <button type="button" class="btn-back " id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide}>Back</button>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default SelectQuestiannaire;
