import React, { useState, useContext, useReducer, useEffect, useRef } from "react";
import TopSection from "../../Includes/Layouts/Common/TopSection";
import Button from "../../UI/Button";
import UKVD from "../../Includes/Layouts/Common/UKVD";
import RadioQuestiannaire from "../../Includes/Layouts/Common/RadioQuestiannaire";
import InputQuestiannaire from "../../Includes/Layouts/Common/InputQuestionnaire";
import SelectQuestiannaire from "../../Includes/Layouts/Common/SelectQuestiannaire";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import RadioQuestiannaireC2 from "../../Includes/Layouts/Common/RadioQuestiannaireC2";
import CheckBoxQuestionnaire from "../../Includes/Layouts/Common/CheckBoxQuestionnaire";
import Loader from "../../Includes/Layouts/Common/Loader";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";
import { useTotalCarCheckLookUp } from "../../../Hooks/useTotalCarCheckLookUp";
import { queryString } from "../../../Utility/QueryString";
import { useForm } from "react-hook-form";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import QuestionnaireModal from "../../Includes/Layouts/Common/QuestionnaireModal"
import { useHistory } from "react-router-dom";
import { QualificationCheck } from "../../../Utility/QualificationCheck";
import { userInfo } from "../../../Hooks/userInfo";
import { FormData } from "../../../Utility/FormData";
import { UUID } from "../../../Utility/UUID";
import GTMDataLayer from "../../Includes/Layouts/GTMDataLayer";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";


import {
  questionnaire21,
  questionnaire22,
  questionnaire23,
  questionnaire24,
  questionnaire25,
  questionnaire26,
  questionnaire27,
  questionnaire28,
  questionnaire29,
  questionnaire30,
  questionnaire31,
  questionnaire32,
  questionnaire33,
  questionnaire34,
  questionnaire35,
  questionnaire36,
  questionnaire37,
} from "../../../Constants/BlackhorseQuestions";

const initialState = {
  showLetsBeginSlide: "d-block",
  showQuestionSlide1: "d-none",
  showQuestionSlide2: "d-none",
  showQuestionSlide_21: "d-none",
  showQuestionSlide_22: "d-none",
  showQuestionSlide_23: "d-none",
  showQuestionSlide_24: "d-none",
  showQuestionSlide_25: "d-none",
  showQuestionSlide_26: "d-none",
  showQuestionSlide_27: "d-none",
  showQuestionSlide_28: "d-none",
  showQuestionSlide_29: "d-none",
  showQuestionSlide_30: "d-none",
  showQuestionSlide_31: "d-none",
  showQuestionSlide_32: "d-none",
  showQuestionSlide_33: "d-none",
  showQuestionSlide_34: "d-none",
  showQuestionSlide_35: "d-none",
  showQuestionSlide_36: "d-none",
  showQuestionSlide_37: "d-none",
};

const QuestionReducer = (state, action) => {
  switch (action.type) {
    case "nextQuestionSlide": {
      if (action.payload.clickedSlide.slide == "lets_begin") {
        return {
          ...state,
          showLetsBeginSlide: "d-none",
          showQuestionSlide1: "d-block",
        };
      } else if (action.payload.clickedSlide.slide == "btnNext2") {
        return {
          ...state,
          showQuestionSlide1: "d-none",
          showQuestionSlide2: "d-block",
        };
      } else if (action.payload.clickedSlide.slide == "keepDate") {
        return {
          ...state,
          showQuestionSlide2: "d-none",
          showQuestionSlide_21: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_21_yes") {
        return {
          ...state,
          showQuestionSlide_21: "d-none",
          showQuestionSlide_22: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_22") {
        return {
          ...state,
          showQuestionSlide_22: "d-none",
          showQuestionSlide_24: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_22_no") {
        return {
          ...state,
          showQuestionSlide_22: "d-none",
          showQuestionSlide_23: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "next_23") {
        return {
          ...state,
          showQuestionSlide_23: "d-none",
          showQuestionSlide_24: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "next_24") {
        return {
          ...state,
          showQuestionSlide_24: "d-none",
          showQuestionSlide_25: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_25") {
        return {
          ...state,
          showQuestionSlide_25: "d-none",
          showQuestionSlide_26: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_26") {
        return {
          ...state,
          showQuestionSlide_26: "d-none",
          showQuestionSlide_28: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_26_no") {
        return {
          ...state,
          showQuestionSlide_26: "d-none",
          showQuestionSlide_27: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_27") {
        return {
          ...state,
          showQuestionSlide_27: "d-none",
          showQuestionSlide_29: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_28") {
        return {
          ...state,
          showQuestionSlide_28: "d-none",
          showQuestionSlide_29: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_29") {
        return {
          ...state,
          showQuestionSlide_29: "d-none",
          showQuestionSlide_30: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "next_30") {
        return {
          ...state,
          showQuestionSlide_30: "d-none",
          showQuestionSlide_31: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "next_31") {
        return {
          ...state,
          showQuestionSlide_31: "d-none",
          showQuestionSlide_32: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "next_32") {
        return {
          ...state,
          showQuestionSlide_32: "d-none",
          showQuestionSlide_33: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_33") {
        return {
          ...state,
          showQuestionSlide_33: "d-none",
          showQuestionSlide_36: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_34_no") {
        return {
          ...state,
          showQuestionSlide_34: "d-none",
          showQuestionSlide_35: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_36_yes") {
        return {
          ...state,
          showQuestionSlide_36: "d-none",
          showQuestionSlide_37: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_36") {
        return {
          ...state,
          showQuestionSlide_36: "d-none",
          showQuestionSlide_34: "d-block",
        };
      }
      else if (action.payload.clickedSlide.slide == "question_37") {
        return {
          ...state,
          showQuestionSlide_37: "d-none",
          showQuestionSlide_34: "d-block",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "backQuestionSlide": {
      if (action.payload.prevSlide.slide == "back1") {
        return {
          ...state,
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_21") {
        return {
          ...state,
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_22") {
        return {
          ...state,
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_22_yes") {
        return {
          ...state,
          showQuestionSlide_24: "d-none",
          showQuestionSlide_22: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_22_no") {
        return {
          ...state,
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_23") {
        return {
          ...state,
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_25") {
        return {
          ...state,
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_26") {
        return {
          ...state,
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_28") {
        return {
          ...state,
          showQuestionSlide_28: "d-none",
          showQuestionSlide_26: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_27") {
        return {
          ...state,
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_26_yes") {
        return {
          ...state,
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_26_no") {
        return {
          ...state,
          showQuestionSlide_29: "d-none",
          showQuestionSlide_27: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_30") {
        return {
          ...state,
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_31") {
        return {
          ...state,
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_32") {
        return {
          ...state,
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_33") {
        return {
          ...state,
          showQuestionSlide_33: "d-none",
          showQuestionSlide_32: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_36_yes") {
        return {
          ...state,
          showQuestionSlide_34: "d-none",
          showQuestionSlide_37: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "question_36_no") {
        return {
          ...state,
          showQuestionSlide_34: "d-none",
          showQuestionSlide_36: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_35") {
        return {
          ...state,
          showQuestionSlide_35: "d-none",
          showQuestionSlide_34: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_36") {
        return {
          ...state,
          showQuestionSlide_36: "d-none",
          showQuestionSlide_33: "d-block",
        };
      }
      else if (action.payload.prevSlide.slide == "back_37") {
        return {
          ...state,
          showQuestionSlide_37: "d-none",
          showQuestionSlide_36: "d-block",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
  }
};
const Questionnaire_BH = () => {
  const [clickedSlide, setClickedSlide] = useState();
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [loader, showLoader] = useState("d-none");
  const [selectedKeeperDate, setSelectedKeeperDate] = useState("");
  const [carRegNo, setCarRegNo] = useState("");
  const [prevSlide, setPrevSlide] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const { getValues } = useForm({});
  const { isLoading, SaveQuestionnaire, storeVehicleDetails } = useQuestionnaire();
  const { totalCarCheckLookUp } = useTotalCarCheckLookUp();
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleSecureYear, setVehicleSecureYear] = useState('');
  const formdata = JSON.parse(localStorage.getItem('formData'));
  const [showModal, setShowModal] = useState(false);
  const splitForm = useRef(null);
  const history = useHistory();
  const [currentKeeperDate, setCurrentKeeperDate] = useState('');
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const {DynamicRouteSplitName,DynamicRouteNextPage}   = DynamicRoutingSplit('UNFR_0602UFBH_A1','0602UFBH_A1/questionnaire');
  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == '') {
      (async () => {
        const response = await getUserInfo(
          uuid
        );
        const set_formdata = setFormData(
          response
        );
        let dataLength = (response.data.response).length;
        if (dataLength > 0) {
          const firstName = (response.data.response[0].first_name) ? (response.data.response[0].first_name) : ' ';
          const lastName = (response.data.response[0].last_name) ? (response.data.response[0].last_name) : ' ';
          setFirstName(firstName);
          setLastName(lastName);
        }
      })();
    }
    else {
      const firstName = formdata.txtFName;
      const lastName = formdata.txtLName;
      setFirstName(firstName);
      setLastName(lastName);
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (prevSlide) {
      dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
    }
  }, [prevSlide]);
  const slideChange = async (e, fieldName) => {
    if (e.target.value == '405' || e.target.value == '407') {
      setShowModal(true);
      return false;
    }
    else if (e.target.value == '382') {
      var currentSlide = 'question_21_yes';
    }
    else if (e.target.value == '385') {
      var currentSlide = 'question_22_no';
    }
    else if (e.target.value == '389') {
      var currentSlide = 'question_26_no';
    }
    else if (e.target.value == '406') {
      var currentSlide = 'question_34_no';
    }
    else if (e.target.value == '406') {
      var currentSlide = 'question_35_no';
    }
    else if (e.target.value == '409') {
      var currentSlide = 'question_36_yes';
    }
    else {
      var currentSlide = e.target.name;
    }
    gtmDataLayer(e.target.name, e.target);
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    setClickedSlide({ slide: currentSlide });
    if (currentSlide == 'question_21_yes' || e.target.value == '383') {
      let vehicle_data = {
        keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        yourVehicle: e.target.dataset.answertext,
        carRegNo: carRegNo,
        pageName: '0602UFBH_A1'
      };
      const response = await storeVehicleDetails(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        formdata,
        queryString,
        vehicle_data,
        "store-vehicle-details",
      )
      var vehicle_details = {
        selecte_keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        your_vehicle: e.target.dataset.answertext,
        car_reg_no: carRegNo
      }
      localStorage.setItem('vehicleDetails', JSON.stringify(vehicle_details));
      if (e.target.value == '383') {
        history.push("/thankyou-unqualified?uuid=" + visitorParameters.visitor_parameters.uuid);
        return false;
      }

    } else if (currentSlide != 'lets_begin' && currentSlide != 'btnNext2' && currentSlide != 'keepDate') {

      var question_id = e.target.dataset.question_id;
      var input_questions = ['23', '24', '30', '31'];

      var optionId = (input_questions.includes(question_id)) ? '' : ((question_id == '32') ? fieldName : form[fieldName].value);
      var inputAnswer = (input_questions.includes(question_id)) ? form[fieldName].value : '';

      let question_data = {
        question_id: question_id,
        option_id: optionId,
        input_answer: inputAnswer,
        bankId: '132'
      };
      if (!(question_id == '34' && optionId == '405') && !(question_id == '35' && optionId == '407')) {
        const response = await SaveQuestionnaire(
          visitorParameters.visitor_parameters,
          question_data,
          "question_store",
          visitorParameters.data,
          queryString,
          formdata
        )
      }

      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries = (existingEntries == null) ? existingEntries = {} : existingEntries;
      Object.assign(newEntries, { [question_id]: (optionId) ? optionId : inputAnswer });
      localStorage.setItem('questionData', JSON.stringify(newEntries));

      if (question_id == '35' && optionId == '408') {
        var isQualified = QualificationCheck();
        let is_qualified = (isQualified) ? 1 : 0;

        const response = await totalCarCheckLookUp(
          visitorParameters.visitor_parameters,
          carRegNo,
          is_qualified,
          currentKeeperDate,
          selectedKeeperDate,
          '0602UFBH_A1'
        )
        if (is_qualified) {
          history.push("/" + DynamicRouteNextPage +"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ DynamicRouteSplitName);
        }
        else {
          history.push("/thankyou-unqualified?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      }
    }
  };

  const gtmDataLayer = (current_question, element = null) => {

    let question = "";
    let answer = "";
    switch (current_question) {
      case "lets_begin":
        question = "unique online service";
        break;
      case "btnNext2":
        question = "Vehicle Registration";
        break;
      case "keepDate":
        question = "registration date";
        break;
      case "question_21":
        question = "Is this your Vehicle?";
        break;
      case "question_22":
        question = "secure finance";
        break;
      case "next_24":
        question = "Approximate amount";
        break;
      case "question_25":
        question = "still in place?";
        break;
      case "question_26":
        question = "still have this vehicle?";
        break;
      case "question_27":
        question = "What is your preference?";
        break;
      case "question_28":
        question = "What is your preference?";
        break;
      case "question_29":
        question = "finance agreement?";
        break;
      case "next_30":
        question = "monthly income";
        break;
      case "next_31":
        question = "credit commitments";
        break;
      case "next_32":
        question = "When my finance agreement began";
        break;
      case "question_33":
        question = "bank account?";
        break;
      case "question_34":
        question = "Subject to IVA";
        break;
      case "question_35":
        question = "scenarios";
        break;
      case "address":
        question = "Personal details, DOB";
        answer = "Completed";
        break;
      case "postcode":
        question = "Postcode";
        answer = "Completed";
        break;
      case "contact":
        question = "Email, Phone";
        answer = "Completed";
        break;
    }
    GTMDataLayer({
      question: question,
      answer: answer
    });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const previousSlide = (e) => {
    const form = splitForm.current;
    if (e.target.name == 'back_24') {
      var checked_22 = form['question_22'].value;
      var previous_slide = (checked_22 == '384') ? 'question_22_yes' : 'question_22_no';
    }
    else if (e.target.name == 'back_29') {
      var checked_26 = form['question_26'].value;
      var previous_slide = (checked_26 == '388') ? 'question_26_yes' : 'question_26_no';
    }
    else if (e.target.name == 'back_34') {
      var checked_36 = form['question_36'].value;
      var previous_slide = (checked_36 == '409') ? 'question_36_yes' : 'question_36_no';
    }
    else {
      var previous_slide = e.target.name;
    }
    setPrevSlide({ slide: previous_slide });
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="Questionnaire_BH">
        <TopSection />
        <section className="d-lg-flex   wrap-question">
          <div className="col-video">
            <video autoPlay muted playsInline loop tabIndex="0">
              <source src="https://loanclaims.unfairfees.co.uk/assets/0602UFMB_A1_1/questionnaire/video/male.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="right-form">

          </div>

        </section>
        <section className={`question_block slide-top slide1 ${state.showLetsBeginSlide}`}>

          <div className="row" >
            <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block">

              <h1>
                {firstName}{" "}{lastName}, our experts have designed a unique online service to assess your claim swiftly and effectively.
              </h1>
              <ul className="list mb-4">
                {/* <li>Our Average Refund for Black Horse as of XX/XX/XXXX is £X,XXX.XX</li> */}
                <li>Over £67,000,000 won by Allegiant across all lenders for unaffordable credit mis-selling</li>
                <li>Over 100,000 served across all lenders</li>
              </ul>
            </div>
            <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
              <div className="  d-flex align-items-center inner-text justify-content-center">
                <div>
                  <Button className="btn com_bnt" name="lets_begin" id="btnNext1" buttonText="LETS BEGIN >>" onClick={slideChange} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Loader show={loader} />
        <QuestionnaireModal popDisplay={showModal} content = "Sorry we cannot accept your application. This is because damages, compensation or settlement money might, in certain circumstances, be set-off against your outstanding liabilities, meaning that you will not receive a cash benefit." handleClose={handleClose} />
        <form ref={splitForm} >
          <UKVD
            showQuestionSlide1={state.showQuestionSlide1}
            showQuestionSlide2={state.showQuestionSlide2}
            slideChange={slideChange}
            uuid={visitorParameters.visitor_parameters.uuid}
            page_name="0602UFBH_A1"
            showLoader={showLoader}
            setSelectedKeeperDate={setSelectedKeeperDate}
            setCarRegNo={setCarRegNo}
            previousSlide={previousSlide}
            setVehicleModel={setVehicleModel}
            setVehicleSecureYear={setVehicleSecureYear}
            setCurrentKeeperDate={setCurrentKeeperDate}
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_21}
            questionnaire={questionnaire21}
            slideChange={slideChange}
            previousSlide={previousSlide}
            vehicleModel={vehicleModel}
          //Is this your Vehicle?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_22}
            questionnaire={questionnaire22}
            slideChange={slideChange}
            previousSlide={previousSlide}
            vehicleSecureYear={vehicleSecureYear}
          //Did you secure finance for this vehicle in 
          />
          <SelectQuestiannaire
            showQuestionSlide={state.showQuestionSlide_23}
            questionnaire={questionnaire23}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //When did you secure finance for this vehicle?
          />
          <InputQuestiannaire
            showQuestionSlide={state.showQuestionSlide_24}
            questionnaire={questionnaire24}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Approximate amount of the finance agreement?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_25}
            questionnaire={questionnaire25}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Is your finance with Black Horse still in place?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_26}
            questionnaire={questionnaire26}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Do you still have this vehicle?
          />
          <RadioQuestiannaireC2
            showQuestionSlide={state.showQuestionSlide_27}
            questionnaire={questionnaire27}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //What happened to the vehicle?
          />
          <RadioQuestiannaireC2
            showQuestionSlide={state.showQuestionSlide_28}
            questionnaire={questionnaire28}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //What is your preference on what happens to this vehicle?
          />
          <RadioQuestiannaireC2
            showQuestionSlide={state.showQuestionSlide_29}
            questionnaire={questionnaire29}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Did Volkswagen check you had enough disposible income to afford the monthly repayments of the new finance agreement?
          />
          <SelectQuestiannaire
            showQuestionSlide={state.showQuestionSlide_30}
            questionnaire={questionnaire30}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Approximately what was your average or usual monthly income (after tax) when your finance agreement began?
          />
          <SelectQuestiannaire
            showQuestionSlide={state.showQuestionSlide_31}
            questionnaire={questionnaire31}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //When your finance agreement began, approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)
          />
          <CheckBoxQuestionnaire
            showQuestionSlide={state.showQuestionSlide_32}
            questionnaire={questionnaire32}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //When my finance agreement began, I was…
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_33}
            questionnaire={questionnaire33}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //When the finance agreement began, were these things typically happening in your bank account?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_34}
            questionnaire={questionnaire34}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_35}
            questionnaire={questionnaire35}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Do any of these scenarios apply to you?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_36}
            questionnaire={questionnaire36}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Did you arrange finance via the dealer or a broker?
          />
          <RadioQuestiannaire
            showQuestionSlide={state.showQuestionSlide_37}
            questionnaire={questionnaire37}
            slideChange={slideChange}
            previousSlide={previousSlide}
          //Would you like us to investigate and, where appropriate, claim for unfair/hidden commission inappropriately charged to you?
          />
        </form>
      </div>

    </>
  );
};

export default Questionnaire_BH;
