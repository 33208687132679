import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import { useCarRegisterValidation } from "../../../../Hooks/useCarRegisterValidation";


const UKVD = ({
  showQuestionSlide1,
  slideChange,
  showQuestionSlide2,
  uuid,
  page_name,
  showLoader,
  setSelectedKeeperDate,
  setCarRegNo,
  previousSlide,
  setVehicleModel,
  setVehicleSecureYear,
  setCurrentKeeperDate
}) => {
  const [carRegistrationNumber, setCarRegistrationNumber] = useState();
  const { registrationvalidation } = useCarRegisterValidation();
  const [keeperDates, setKeeperDates] = useState([]);
  const [error, setError] = useState("");
  const [showError, setshowError] = useState('d-none');

  const UKVD_Validation = (e) => {
    e.preventDefault();
    let registration_no = carRegistrationNumber;
    if (registration_no) {
      var carRegNo = registration_no.replace(/\s+/g, "");
      setCarRegNo(carRegNo);
      CarRegisterValidationApi(e, carRegNo);
    } else {
      document.getElementById("question_1").focus();
      setshowError('d-block');
      setError("Please Enter Car Registration Number");
      return false;
    }
  }
  const CarRegisterValidationApi = async (e, carRegNo) => {
    showLoader("d-block");
    const getRegisterValidation = await registrationvalidation(
      carRegNo,
      uuid,
      page_name
    );
    if (getRegisterValidation.data.validity == "1") {
      let vehicleResponse = getRegisterValidation.data.vehicle_api_response;
      let vehicleDetails = JSON.parse(vehicleResponse);
      let vehicle_make = getRegisterValidation.data.vehicle_make;
      let fuel_type = vehicleDetails.DataItems.VehicleRegistration.FuelType;
      let vehicle = getRegisterValidation.data.keeper_date;
      let keeperData = JSON.parse(vehicle);
      keeperData = keeperData.keeper_dates;
      keeperCurrentDate(keeperData);
      setVehicleModel(getRegisterValidation.data.vehicle_model);
      setTimeout(() => {
        showLoader("d-none");
        slideChange(e);
      }, 2000);
      setshowError('d-none');
      setKeeperDates(keeperData);
    } else {
      setshowError('d-block');
      setError("Your Vehicle Registration is Invalid. Please Recheck!");
      showLoader("d-none");
      return false;
    }
  };
  const selectKeeperData = (e) => {
    let s_keeperDate = e.target.getAttribute('data-keeper_data');
    let split_keeperDate = s_keeperDate.split(/(\s+)/);
    let keeper_year = split_keeperDate[4];
    setSelectedKeeperDate(s_keeperDate);
    localStorage.setItem('selected_keeper_date', s_keeperDate);
    slideChange(e);
    setVehicleSecureYear(keeper_year);
  }
  const clickCarRegNo = (e) => {
    setCarRegistrationNumber(e.target.value);
    setshowError('d-none');
  }
  const responseDates = keeperDates.map((value, key) => {
    return (

      <div className="mb-3 " >
        <div className="btn-date" id="btnNext3">
          <RadioButtonQuest
            className="form-check-input"
            labelName={value}
            value={value}
            name="keepDate"
            id={`keepDate${key}`}
            onClick={selectKeeperData}
            dataKeeperData={value}
          />
        </div>
      </div>
    );
  });

  const keeperCurrentDate = (keeperDates) => {
    var currentDate = keeperDates[0],
      newCurrentDate = new Date(keeperDates[0]);
    keeperDates.forEach(function (dt, index) {
      if (new Date(dt) > newCurrentDate) {
        currentDate = dt;
        newCurrentDate = new Date(dt);
      }
    });
    setCurrentKeeperDate(currentDate);
  }

  return (
    <>
      <section className={`question_block slide-top slide2 ${showQuestionSlide1}`}>
        <div className="row" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block  d-flex align-items-center  justify-content-center justify-content-lg-left">
            <div>
              <h1>
                Please add your Vehicle Registration
              </h1>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                <div className="mb-lg-5 mb-sm-4 mb-4">
                  <TextField
                    type="text"
                    className="form-control input-box car_number"
                    placeholder="Enter Car Registration Number"
                    name="question_1"
                    id="question_1"
                    value={carRegistrationNumber}
                    onChange={clickCarRegNo}
                  ></TextField>
                  <span className={`error_msg error_msg_custom ${showError}`} id="email_err">
                    {error}
                  </span>
                </div>
                <Button className="btn com_bnt" id="btnNext2" name="btnNext2" buttonText="Start My Check" onClick={UKVD_Validation} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`question_block slide-top slide3 ${showQuestionSlide2}`}>
        <div className="row" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block  d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                Select the date the car was registered to you
              </h1>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                {responseDates}
                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <Button type="button" className="btn-back" name="back1" id="back1" onClick={previousSlide} buttonText="Back" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UKVD;
