
export const QualificationCheck = () => {
    const localQuestionnaire = JSON.parse(localStorage.getItem("questionData"));
    const getFinanceYearGap = () => {
        var qualified_year_gap = 1;
        if ((localQuestionnaire[23]) && ((localQuestionnaire[22]) && localQuestionnaire[22] == '385')) {
            let financeYear = parseInt(localQuestionnaire[23]);
            let currentYear = parseInt(new Date().getFullYear());
            let yearGap = currentYear - financeYear;
            qualified_year_gap = (yearGap > 2) ? 1 : 0;
        } else {

            let today = new Date();
            var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let select_keeper_date = localStorage.getItem("selected_keeper_date");
            if(!select_keeper_date) return true;
            let split_keeperDate = select_keeper_date.split(/(\s+)/);

            var month_gap;
            month_gap = (parseInt(today.getFullYear()) - parseInt(split_keeperDate[4])) * 12;
            month_gap -= parseInt((months.indexOf(split_keeperDate[2]) + 1));
            month_gap += parseInt(today.getMonth() + 1);
            qualified_year_gap = (month_gap > 24) ? 1 : 0;
        }
        return qualified_year_gap;
    };
    var qualified_23 = 1;
    var qualified_29 = 1;
    var qualified_32 = 1;
    var qualified_33 = 1;
    var qualified_30_31 = 0;
    var income = '';
    var payment = '';
    var percentage = 0;
    var qualified_25_26_28 = 1;

    var financeYearGap = getFinanceYearGap();

    if ((localQuestionnaire[23]) && localQuestionnaire[23] <= 2007) {
        qualified_23 = 0;
    }

    if ((localQuestionnaire[29]) && localQuestionnaire[29] == '398') {
        qualified_29 = 0;
    }

    if ((localQuestionnaire[30])) {
        if (localQuestionnaire[30] == 'Less than £100' || localQuestionnaire[30] == 'over £4600') {
            income = parseInt(localQuestionnaire[30].split('£')[1]);
        } else {
            income = parseInt(localQuestionnaire[30].split('-')[0].split('£')[1]);
        }
    }

    if ((localQuestionnaire[31])) {
        if (localQuestionnaire[31] == 'Less than £100' || localQuestionnaire[31] == 'over £2000') {
            payment = parseInt(localQuestionnaire[31].split('£')[1]);
        } else {
            payment = parseInt(localQuestionnaire[31].split('-')[0].split('£')[1]);
        }
    }

    if ((localQuestionnaire[32]) && (localQuestionnaire[32].length == 1 && localQuestionnaire[32][0] == '402')) {
        qualified_32 = 0;
    }

    if ((localQuestionnaire[33]) && (localQuestionnaire[33]) == '404') {
        qualified_33 = 0;
    }

    if (income != '' && payment != '') {
        percentage = (payment / income) * 100;
    }
    if (percentage > 20) {
        qualified_32 = qualified_33 = 1;
        qualified_30_31 = 1;
    } else if (percentage < 20 && (qualified_32 || qualified_33)) {
        qualified_32 = qualified_33 = 1;
        qualified_30_31 = 1;
    }

    if (((localQuestionnaire[25]) && (localQuestionnaire[25]) == '386') && ((localQuestionnaire[26]) && (localQuestionnaire[26]) == '388') && ((localQuestionnaire[28]) && (localQuestionnaire[28]) == '395')) {
        qualified_25_26_28 = 0;
    }

    if (qualified_23 && qualified_29 && qualified_32 && qualified_33 && qualified_30_31 && qualified_25_26_28 && financeYearGap) {
        return true;
    } else {
        return false;
    }

}